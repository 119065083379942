export const kCommunityPageContent = {
  pageName: "社区",
  content: `按照不同的兴趣、爱好、人群特征分类，我们社群分为三大类：自然农法宣讲团、蕙芷香遇俱乐部、天下商邦私享会。如果您对加入社群感兴趣，您可以通过相应二维码联系工作人员进入。`,
  pages: [
    {
      pageName: "蕙芝香遇俱乐部",
      img: require("@/assets/community/club.png"),
      qrCode: require("@/assets/community/hzxy_qr.png"),
      details: [
        {
          des: `以对自然之敬畏与感恩，向植物借力，与植物一起修行！蕙芷融合西方芳疗、中医思维、东方智慧，在蕙芷香寓的“寓主”带领下，通过香薰、香灸、按摩推拿、瑜伽、冥想、吐纳、调息……，一起沉浸享受这来自植物的香氛世界。`,
        },
      ],
    },
    {
      pageName: "自然农法宣讲团",
      img: require("@/assets/community/preach.png"),
      qrCode: require("@/assets/community/zrnf_qr.png"),
      details: [
        {
          des: `归农自然农法社区宣讲团，招募志愿者、义工，倡导自然农法，让我们及后代食用更有力量的食物，让子孙后代居住在更生态的环境。`,
        },
      ],
    },
    // {
    //   pageName: "天下商邦私享会",
    //   img: require("@/assets/community/groupbuy.png"),
    //   qrCode: require("@/assets/community/txsb_qr.png"),
    //   details: [
    //     {
    //       des: `天下商邦是一首歌／一杯酒／一群人，是由多位知名企业家／导演／学者／作家／记者……联合发起的一个创业者社群，传承发扬中国商邦文化与创业者精神，我们一起温暖前行。`,
    //     },
    //     {
    //       title: "企业俱乐部",
    //       des: `企业家俱乐部采用研讨、交流，集结广大各界企业家智慧，帮助企业家运用更前沿的思维与管理方法，打造企业家互为赋能、共创共赢的圈层平台，为未来发展提供持续动力。`,
    //       rules: [],
    //     },
    //     {
    //       title: "城市分会",
    //       des: `有能力、有精力、有意愿的人，可以在自己当地申请开设天下商邦私享会的城市分会，独立组织各种线上线下的私享会活动。如：`,
    //       rules: [
    //         `1．邀约人参与总会发起的“名人名企私享会“`,
    //         `2．邀约人参与总会发起的“量商学院”课程`,
    //         `3．独立组织各种产品的私享会`,
    //         `4．独立组织天下商邦私享会项目推荐会活动`,
    //         `5．独立组织盲盒饭局、户外徒步等各类社交娱乐活动`,
    //         `6．任意一个社群社团（各商会、协会、校友会），成为天下商邦“商会联盟”的成员参与活动`,
    //       ],
    //     },
    //     {
    //       title: "量子商学院",
    //       des: `量子商学院将围绕量子思维及管理实践应用，面向不同需求层次的学员开设基础班、专题班、总裁班课程，致力于成为量子管理界的“黄埔军校”。未来，量子商学院将成立学员俱乐部。俱乐部将凝聚整合圈层资源、搭建学员学习生活交流平台。俱乐部成员有权参加全年各类管理培训课程。`,
    //       rules: [],
    //     },
    //   ],
    // },
  ],
}