<template>
  <div id="page_withFooter">
    <div class="community_content nav_top_padding">
      <div class="community_summary">{{ info.content }}</div>
      <div class="community_img_container">
        <img class="community_qr_code" :src="info.pages[selectedIndex].qrCode" />
      </div>
      <Box :items="info.pages[selectedIndex].details" />
    </div>
    <PageFooter />
  </div>
  <CustomSlider :items="items" v-on:switchTab="handleSwitchTab" :fullImg="true" :styleType="2"/>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import CustomSlider from "@/components/Mobile/CustomSlider.vue";
import Box from "@/components/Mobile/Box.vue";
import PageFooter from "@/components/Mobile/PageFooter.vue";
import { kMobileMenusConfig } from "@/config/Mobile/MenusConfig";
import { kCommunityPageContent } from "@/config/Common/community_data";

require("@/less/mobile/community_style.less");

@Options({
  components: {
    CustomSlider,
    Box,
    PageFooter,
  },
  data() {
    return {
      selectedIndex: 0,
      items: kMobileMenusConfig.sq,
      info: kCommunityPageContent,
    };
  },
  methods: {
    handleSwitchTab(index) {
      this.selectedIndex = index;
    },
  },
})
export default class Page extends Vue {}
</script>

<style>
@import url("../../../less/mobile/page.less");
</style>
<style scoped>
  .nav_top_padding{
    padding-top: calc( 400 / 1080 * 100vw);
  }
</style>